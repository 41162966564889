import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';

// core components
import styles from './CustomFormControl.style.js';

const useStyles = makeStyles(styles);

export default function CustomFormControl(props) {
  const classes = useStyles();
  const { formControlProps, labelText, labelClasses, id, labelProps, children, inputType } = props;

  return (
    <FormControl
      variant="standard"
      {...formControlProps}
      className={formControlProps.className + ' ' + classes.formControl}
    >
      {labelText !== undefined ? (
        inputType === 'date' ? (
          <InputLabel className={classes.labelDate + labelClasses} htmlFor={id} {...labelProps} shrink={true}>
            {labelText}
          </InputLabel>
        ) : (
          <InputLabel className={classes.labelRoot + labelClasses} htmlFor={id} {...labelProps}>
            {labelText}
          </InputLabel>
        )
      ) : null}
      {children}
    </FormControl>
  );
}

CustomFormControl.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  formControlProps: PropTypes.object,
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
};
