import gql from 'graphql-tag';

export function getModelFragment(Model, modelFields = '') {
  return gql`
    fragment ${Model}Fields on ${Model} {
      ${modelFields}
    }
  `;
}

export function getCrudQueries(Model, modelFields, fragments = '', filter = '', sortable = false) {
  return [
    getModelList(Model, modelFields, fragments, filter, sortable),
    getGetModel(Model, modelFields, fragments),
    getCreateModel(Model, modelFields, fragments),
    getUpdateModel(Model, modelFields, fragments),
    getDeleteModel(Model),
  ];
}

export function getModelList(Model, modelFields, fragments, filter, sortable) {
  const pluralModelName = `${Model.replace('Company', 'Companie')
    .replace(/Batch$/gm, 'Batche')
    .replace('Category', 'Categorie')}s`;
  const cursorType = Model === 'APIRequest' ? 'ID' : 'Int';
  const _filter = filter || `${Model}Input`;
  return gql`
    query List${pluralModelName}($cursor: ${cursorType}, $limit: Int, $filter: ${_filter} ${
    sortable ? `, $sort: ${Model}SortInput` : ''
  }) {
      list${pluralModelName}(cursor: $cursor, limit: $limit, filter: $filter  ${sortable ? `, sort: $sort` : ''}) {
        data {
          ...${Model}Fields
        }
        pagination {
          hasNextPage
          cursor
        }
      }
    }

    ${getModelFragment(Model, modelFields)}

    ${fragments}
  `;
}

export function getGetModel(Model, modelFields, fragments) {
  return gql`
    query Get${Model}($_id: ID!) {
      get${Model}(_id: $_id) {
        ...${Model}Fields
      }
    }

    ${getModelFragment(Model, modelFields)}

    ${fragments}
  `;
}

export function getCreateModel(Model, modelFields, fragments) {
  return gql`
    mutation Create${Model}($input: ${Model}Input!) {
      create${Model}(input: $input) {
        ...${Model}Fields
      }
    }

    ${getModelFragment(Model, modelFields)}

    ${fragments}
  `;
}

export function getUpdateModel(Model, modelFields, fragments) {
  return gql`
    mutation Update${Model}(
      $_id: ID!
      $input: ${Model}Input!
    ) {
      update${Model}(
        _id: $_id
        input: $input
      ) {
        ...${Model}Fields
      }
    }

    ${getModelFragment(Model, modelFields)}

    ${fragments}
  `;
}

export function getDeleteModel(Model) {
  return gql`
    mutation Delete${Model}($_id: ID!) {
      delete${Model}(_id: $_id) {
        success
        message
      }
    }
  `;
}
