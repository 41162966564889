import store from '@/helpers/persistent';
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import fetch from 'isomorphic-unfetch';

const NEXT_PUBLIC_API_DOMAIN = process.env.NEXT_PUBLIC_API_DOMAIN;

export default function createApolloClient(initialState, ctx) {
  // The `ctx` (NextPageContext) will only be present on the server.
  // use it to extract auth headers (ctx.req) or similar.

  const httpLink = createHttpLink({
    uri: `${NEXT_PUBLIC_API_DOMAIN}/admin/graphql`, // Server URL (must be absolute)
    credentials: 'same-origin', // Additional fetch() options like `credentials` or `headers`
    fetch,
  });

  const authLink = setContext(({ operationName }, { headers }) => {
    let token = store.get('auth_token');
    const shouldSetAuthHeader = operationName !== 'Login' && !!token;

    return {
      headers: {
        ...headers,
        ...(shouldSetAuthHeader ? { authorization: `${token}` } : {}),
        'X-TIMEZONE-IANA': Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    };
  });

  return new ApolloClient({
    ssrMode: Boolean(ctx),
    link: authLink.concat(httpLink),
    cache: new InMemoryCache().restore(initialState),
  });
}
