import { CURRENT_USER } from '@/gql/users';
import store from '@/helpers/persistent';
import { useLazyQuery } from '@apollo/react-hooks';
import React, { useEffect, useState } from 'react';

export function useCheckUser() {
  const [user, setUser] = useState(store.get('user'));
  const [token, setToken] = useState(store.get('auth_token'));
  const [loadCurrentUser, { loading, error, data }] = useLazyQuery(CURRENT_USER);

  useEffect(() => {
    if (error) {
      setUser(null);
      setToken(null);
      store.set('auth_token', null);
      store.set('user', null);
    }
  }, [error]);

  useEffect(() => {
    if (data && data?.currentUser?.user && data?.currentUser?.token) {
      setUser(data.currentUser.user);
      setToken(data.currentUser.token);
      store.set('user', data.currentUser.user);
      store.set('auth_token', data.currentUser.token);
    }
  }, [data]);

  return [loadCurrentUser, user, loading || (!data && !error), token];
}
