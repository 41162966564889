import CustomInput from '@/components/common/CustomInput/CustomInput';
import clsx from 'clsx';
import dasherize from 'dasherize';
import PropTypes from 'prop-types';
import React from 'react';

const defaultOnCLick = () => {};

export function FormEditTextField({
  label = '',
  className = '',
  type = 'text',
  value,
  onChange = defaultOnCLick,
  hasAnyOption = false,
  ...props
}) {
  return (
    <CustomInput
      labelText={label}
      formControlProps={{
        fullWidth: true,
      }}
      inputProps={{
        name: dasherize(label),
        value,
        onChange,
        type,
        className: clsx('FormEditTextField', className),
        ...props,
      }}
    />
  );
}

FormEditTextField.propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  type: PropTypes.oneOf(['text', 'number', 'email', 'password', 'tel', 'url', 'file', 'date']),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  onChange: PropTypes.func,
};
