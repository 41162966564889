import LinearProgress from '@mui/material/LinearProgress';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';

import style from './GlobalProgress.style';

const useStyles = makeStyles(style);

export default function GlobalProgress({ show, className = '' }) {
  const classes = useStyles();

  return (
    <LinearProgress
      classes={classes}
      className={className}
      style={{
        display: show ? 'block' : 'none',
      }}
    />
  );
}

GlobalProgress.propTypes = {
  show: PropTypes.bool,
};
