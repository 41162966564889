import Button from '@/components/common/Button/Button';
// core components
// import CustomInput from '@/components/common/CustomInput/CustomInput';
import store from '@/helpers/persistent';
// @mui/icons-material
import PersonIcon from '@mui/icons-material/Person';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Divider from '@mui/material/Divider';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Poppers from '@mui/material/Popper';
// @mui/material components
import { makeStyles } from '@mui/styles';
// import Search from '@mui/icons-material/Search';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import React from 'react';

import styles from './AdminNavbarLinks.style.js';

const useStyles = makeStyles(styles);

export default function AdminNavbarLinks() {
  const user = store.get('user');

  const router = useRouter();
  const classes = useStyles();
  const [openProfile, setOpenProfile] = React.useState(null);
  const handleClickProfile = (event) => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };

  const handleCloseProfile = () => {
    setOpenProfile(null);
  };

  const handleProfile = () => {
    if (user && user._id) {
      router.push(`/users/${user._id}`);
    }
  };

  const handleLogout = () => {
    store.set('auth_token', null);
    store.set('user', null);
    handleCloseProfile();
    window.location.reload();
  };

  return (
    <div>
      {/*<div className={classes.searchWrapper}>*/}
      {/*  <CustomInput*/}
      {/*    formControlProps={{*/}
      {/*      className: classes.margin + " " + classes.search*/}
      {/*    }}*/}
      {/*    inputProps={{*/}
      {/*      placeholder: "Search",*/}
      {/*      inputProps: {*/}
      {/*        "aria-label": "Search"*/}
      {/*      }*/}
      {/*    }}*/}
      {/*  />*/}
      {/*  <Button color="white" aria-label="edit" justIcon round>*/}
      {/*    <Search />*/}
      {/*  </Button>*/}
      {/*</div>*/}

      <div className={classes.manager}>
        {user && user.name ? (
          <>
            <span>Logged in as</span> <strong>{user.name}</strong>
          </>
        ) : null}
        <Button
          id="navbar-user-icon"
          color="transparent"
          justIcon={true}
          simple={false}
          aria-owns={openProfile ? 'profile-menu-list-grow' : null}
          aria-haspopup="true"
          onClick={handleClickProfile}
          className={classes.buttonLink}
          size="lg"
        >
          <PersonIcon className={classes.icons} />
        </Button>
        <Poppers
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          className={clsx({ [classes.popperClose]: !openProfile }) + ' ' + classes.popperNav}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list-grow"
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    <MenuItem id="profile-menu-item" onClick={handleProfile} className={classes.dropdownItem}>
                      Profile
                    </MenuItem>
                    {/*<MenuItem onClick={handleCloseProfile} className={classes.dropdownItem}>*/}
                    {/*  Settings*/}
                    {/*</MenuItem>*/}
                    <Divider light />
                    <MenuItem id="logout-menu-item" onClick={handleLogout} className={classes.dropdownItem}>
                      Logout
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>
    </div>
  );
}
