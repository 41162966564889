import AdjustIcon from '@mui/icons-material/Adjust';
import AppsIcon from '@mui/icons-material/Apps';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import CallMadeIcon from '@mui/icons-material/CallMade';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import DescriptionIcon from '@mui/icons-material/Description';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import HighQualityIcon from '@mui/icons-material/HighQuality';
import HomeIcon from '@mui/icons-material/Home';
import HttpIcon from '@mui/icons-material/Http';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import UserIcon from '@mui/icons-material/Person';
import Print from '@mui/icons-material/Print';
import SettingsIcon from '@mui/icons-material/Settings';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';

const routes = [
  {
    path: '/',
    name: 'Home',
    icon: HomeIcon,
  },
  {
    path: '/orders',
    name: 'Orders',
    icon: DashboardRoundedIcon,
  },
  {
    path: '/products',
    name: 'Products',
    icon: ShoppingBasketIcon,
  },
  {
    path: '/product-categories',
    name: 'Product Categories',
    icon: LocalOfferIcon,
  },
  {
    path: '/parcels',
    name: 'Parcels',
    icon: CardGiftcardIcon,
  },
  {
    path: '/client-companies',
    name: 'Client Companies',
    icon: BusinessRoundedIcon,
  },
  {
    path: '/quality-control-stations',
    name: 'Quality Control Stations',
    icon: HighQualityIcon,
  },
  {
    path: '/print-batches',
    name: 'Print Batches',
    icon: DynamicFeedIcon,
  },
  {
    path: '/print-batch-types',
    name: 'Print Batch Types',
    icon: AppsIcon,
  },
  {
    path: '/printers',
    name: 'Printers',
    icon: Print,
  },
  // {
  //   path: '/rollslist',
  //   name: 'Rolls',
  //   icon: AdjustIcon,
  // },
  // TODO: re-enable me! Hidden intentionally to prevent user access (admins have url bookmarked)
  // {
  //   path: '/checkpoint-settings-presets',
  //   name: 'Checkpoint Settings',
  //   icon: LibraryAddCheckIcon,
  // },
  // {
  //   path: '/print-batch-roll-builder',
  //   name: 'Print Batch Roll Builder',
  //   icon: Dashboard,
  // },
  {
    path: '/reports',
    name: 'Reports',
    icon: DescriptionIcon,
  },
  {
    path: '/users',
    name: 'Users',
    icon: UserIcon,
  },
  {
    path: '/webhooks',
    name: 'Webhooks',
    icon: CallMadeIcon,
  },
  {
    path: '/api-requests',
    name: 'API Requests',
    icon: HttpIcon,
  },
  {
    path: '/settings',
    name: 'Settings',
    icon: SettingsIcon,
  },
];

export function isActiveRoute({ path, router }) {
  return path === '/' ? router.asPath === path : router.asPath.startsWith(path);
}

export default routes;
