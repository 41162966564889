import { getCrudQueries } from '@/gql/gql.template';
import { PERMISSION_SYSTEMS } from '@/helpers/constants';
import gql from 'graphql-tag';

const content = `
  _id
  role
  name
  email
  lastLoginTimestamp
  createdAt
  updatedAt
  permissions {
    ${Object.values(PERMISSION_SYSTEMS).join(' ')}
  }
`;

const [LIST_USERS, GET_USER, CREATE_USER, UPDATE_USER, DELETE_USER] = getCrudQueries(
  'User',
  content,
  undefined,
  null,
  true
);

const CURRENT_USER = gql`
  query CurrentUser {
    currentUser {
      token
      user {
        ${content}
      }
    }
  }
`;

const LOGIN = gql`
  query Login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      token
      user {
        ${content}
      }
    }
  }
`;
// const LOGIN = gql`
//   query Login($email: Email!, $password: String!) {
//     login(email: $email, password: $password) {
//       token
//       user {
//         ${content}
//       }
//     }
//   }
// `;

const DELETE_USERS = gql`
  mutation DeleteUsers($_ids: [ID]!) {
    deleteUsers(_ids: $_ids) {
      success
    }
  }
`;

export { LIST_USERS, GET_USER, CREATE_USER, UPDATE_USER, DELETE_USER, CURRENT_USER, LOGIN, DELETE_USERS };
