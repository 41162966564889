import { container, defaultFont, grayColor } from '@/jss/material-dashboard-react';

const navbarStyle = () => ({
  appBar: {
    backgroundColor: '#fff',
    boxShadow: '0 2px 4px rgb(0 0 0 / 12%)',
    borderBottom: '0',
    marginBottom: '1rem',
    width: 'calc(100% - 260px)',
    zIndex: '1029',
    color: grayColor[7],
    border: '0',
    borderRadius: '0px',
    padding: '0',
    transition: 'all 150ms ease 0s',
    minHeight: '50px',
    display: 'block',
  },
  container: {
    ...container,
    minHeight: '50px',
  },
  flex: {
    flex: 1,
  },
  title: {
    ...defaultFont,
    letterSpacing: 'unset',
    lineHeight: '30px',
    fontSize: '18px',
    borderRadius: '3px',
    textTransform: 'none',
    color: 'inherit',
    margin: '0',
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
    '&:hover,&:focus': {
      background: 'transparent',
    },
  },
  chevronRight: {
    fill: '#000',
    verticalAlign: 'middle',
  },
  appResponsive: {
    top: '8px',
  },
  // primary: {
  //   backgroundColor: primaryColor[0],
  //   color: whiteColor,
  //   ...defaultBoxShadow
  // },
  // info: {
  //   backgroundColor: infoColor[0],
  //   color: whiteColor,
  //   ...defaultBoxShadow
  // },
  // success: {
  //   backgroundColor: successColor[0],
  //   color: whiteColor,
  //   ...defaultBoxShadow
  // },
  // warning: {
  //   backgroundColor: warningColor[0],
  //   color: whiteColor,
  //   ...defaultBoxShadow
  // },
  // danger: {
  //   backgroundColor: dangerColor[0],
  //   color: whiteColor,
  //   ...defaultBoxShadow
  // }
});

export default navbarStyle;
