import routes, { isActiveRoute } from '@/routes';
import { VerticalAlignBottom } from '@mui/icons-material';
import Drawer from '@mui/material/Drawer';
import Icon from '@mui/material/Icon';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';

import styles from './Sidebar.style.js';

const useStyles = makeStyles(styles);

export default function Sidebar() {
  const classes = useStyles();

  const router = useRouter();

  const activeRoute = (path) => isActiveRoute({ path, router });

  const color = 'blue';

  const links = (
    <List className={classes.list}>
      {routes.map((prop, key) => {
        const listItemClasses = clsx({
          [' ' + classes[color]]: activeRoute(prop.path),
        });

        const whiteFontClasses = clsx({
          [' ' + classes.whiteFont]: activeRoute(prop.path),
        });

        return (
          <Link
            href={prop.path}
            // activeClassName="active"
            key={key}
            className={classes.item}
          >
            <ListItem button className={classes.itemLink + listItemClasses}>
              {typeof prop.icon === 'string' ? (
                <Icon className={clsx(classes.itemIcon, whiteFontClasses)}>{prop.icon}</Icon>
              ) : (
                <prop.icon className={clsx(classes.itemIcon, whiteFontClasses)} />
              )}
              <ListItemText
                primary={prop.name}
                className={clsx(classes.itemText, whiteFontClasses)}
                disableTypography={true}
              />
            </ListItem>
          </Link>
        );
      })}
    </List>
  );
  const brand = (
    <div className={classes.logo}>
      <Link href="/" className={clsx(classes.logoLink)}>
        <div className={classes.logoImage}>
          <img src="/assets/img/logo.png" alt="logo" className={classes.img} />
        </div>
        <span className={classes.logoLinkLabel}>Admin UI</span>{' '}
        <span style={{ verticalAlign: 'super', fontSize: 14 }}>V2</span>
      </Link>
    </div>
  );

  return (
    <Drawer
      anchor={'left'}
      variant="permanent"
      open
      classes={{
        paper: clsx(classes.drawerPaper),
      }}
      className={`Sidebar ${classes.sidebar}`}
    >
      {brand}
      <div className={classes.sidebarWrapper}>{links}</div>
      <div className={classes.background} style={{ backgroundImage: 'url(/assets/img/Atlanta.jpg)' }} />
    </Drawer>
  );
}
