import Button from '@/components/common/Button/Button';
import { getParentPaths } from '@/helpers/utils';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import humanizeString from 'humanize-string';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { Fragment } from 'react';

import AdminNavbarLinks from './AdminNavbarLinks.js';
import navbarStyles from './Navbar.module.scss';
import styles from './Navbar.style';

const useStyles = makeStyles(styles);

export default function Navbar() {
  const router = useRouter();
  const classes = useStyles();

  const parentPaths = getParentPaths(router.asPath);

  return (
    <AppBar className={clsx(classes.appBar, navbarStyles.Navbar)}>
      <Toolbar className={classes.container}>
        <div className={classes.flex}>
          {parentPaths.map(({ title, path }, i) => (
            <Fragment key={path}>
              <Link href={path}>
                <Button color="transparent" className={classes.title}>
                  <strong>{humanizeString(title).toUpperCase()}</strong>
                </Button>
              </Link>
              {i !== parentPaths.length - 1 ? <ChevronRightIcon className={classes.chevronRight} /> : null}
            </Fragment>
          ))}
        </div>
        <AdminNavbarLinks />
      </Toolbar>
    </AppBar>
  );
}
