import { defaultFont, grayColor } from '@/jss/material-dashboard-react';

const customFormControlStyle = {
  formControl: {
    paddingBottom: '10px',
    position: 'relative',
    verticalAlign: 'unset',
  },
  labelRoot: {
    ...defaultFont,
    color: grayColor[3] + ' !important',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '1.42857',
    letterSpacing: 'unset',
  },
  labelDate: {
    ...defaultFont,
    color: grayColor[3] + ' !important',
    fontSize: '16px',
    lineHeight: '1.42857',
  },
};

export default customFormControlStyle;
