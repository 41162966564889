import capitalize from 'capitalize';
import dasherize from 'dasherize';

export function isBrowser() {
  return typeof window !== 'undefined';
}

export function getParentPaths(path) {
  if (path === '/') {
    return [
      {
        title: 'HOME',
        path: `/`,
      },
    ];
  }

  let pathPartials = path.replace(/\?.*/i, '').replace(/#.*/i, '').split('/').filter(Boolean);

  if (pathPartials.length > 2) {
    const normalizedPathPartials = pathPartials.slice(0, 2);

    for (let i = 2; i < pathPartials.length; i += 2) {
      normalizedPathPartials.push(pathPartials.slice(i, i + 2).join('/'));
    }

    pathPartials = normalizedPathPartials;
  }

  return pathPartials.map((partial, i) => {
    return {
      title: partial.replace('s/', ' ').replace(/(\d+)/g, '#$1').toUpperCase(),
      path: `/${pathPartials.slice(0, i + 1).join('/')}`,
    };
  });
}

export function formatAddress({ name, company, street1, street2, city, state, zip, country, phone } = {}) {
  const line1 = [name, company, street1, street2].filter(Boolean).join(', ');

  const line2 = [city, country, state, zip].filter(Boolean).join(', ');

  return `${line1},\n${line2}${phone ? `.\nTel: ${phone}` : ''}`;
}

export function sortBy(list = [], prop = '_id', sortOrder = 'ASC') {
  sortOrder = sortOrder.toUpperCase();
  return [...list].sort((a, b) => (a[prop] > b[prop] ? (sortOrder === 'ASC' ? 1 : -1) : sortOrder === 'ASC' ? -1 : 1));
}

export function toCssClassName(str) {
  return dasherize(capitalize.words(str || '').replace(/\s+/g, ''));
}

export function getShippingLink(trackingCode, shippingCarrier) {
  return (
    {
      usps: `https://tools.usps.com/go/TrackConfirmAction.action?tLabels=${trackingCode}`,
      ups: `http://wwwapps.ups.com/WebTracking/processInputRequest?TypeOfInquiryNumber=T&InquiryNumber1=${trackingCode}`,
      fedex: `https://www.fedex.com/fedextrack/?tracknumbers=${trackingCode}`,
      globegistics: `https://globetrak.globegisticsinc.com/GgTracking.aspx?track=${trackingCode}`,
    }[(shippingCarrier || '').toLowerCase()] || null
  );
}

/**
 *
 * @param {Object} enumObject
 * @returns {{prop: String, title: String}[]}
 */
export const convertEnum2Options = (enumObject) =>
  Object.entries(enumObject).map(([key, value]) => ({
    prop: value,
    title: key,
  }));

export const lengthUnitsConverter = (fromUnits, toUnits, value, unitsOptions) => {
  const rate =
    unitsOptions.find(({ prop }) => prop === fromUnits).rate / unitsOptions.find(({ prop }) => prop === toUnits).rate;
  return Number((value * rate).toFixed(2));
};

export const convertPascalCaseToSentence = (string) =>
  string
    .split(/(?=[A-Z])/)
    .map((word, index) => (index === 0 ? word : word.toLowerCase()))
    .join(' ');
