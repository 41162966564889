import {
  blackColor,
  boxShadow,
  defaultFont,
  drawerWidth,
  drawerWidthSm,
  grayColor,
  hexToRgb,
  infoColor,
  transition,
  whiteColor,
} from '@/jss/material-dashboard-react';

const sidebarStyle = (theme) => ({
  drawerPaper: {
    border: 'none',
    position: 'fixed',
    top: '0',
    bottom: '0',
    left: '0',
    zIndex: '1',
    ...boxShadow,
    width: drawerWidth,
    // [theme.breakpoints.up('md')]: {
    //   width: drawerWidth,
    //   position: 'fixed',
    height: '100%',
    // },
    [theme.breakpoints.down('xl')]: {
      width: drawerWidthSm,
    },
    // [theme.breakpoints.down("sm")]: {
    //   width: drawerWidth,
    //   ...boxShadow,
    //   position: "fixed",
    //   display: "block",
    //   top: "0",
    //   height: "100vh",
    //   right: "0",
    //   left: "auto",
    //   zIndex: "1032",
    //   visibility: "visible",
    //   overflowY: "visible",
    //   borderTop: "none",
    //   textAlign: "left",
    //   paddingRight: "0px",
    //   paddingLeft: "0",
    //   transform: `translate3d(${drawerWidth}px, 0, 0)`,
    //   ...transition
    // }
  },
  logo: {
    position: 'relative',
    padding: '15px 15px',
    zIndex: '4',
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: '0',

      height: '1px',
      right: '15px',
      width: 'calc(100% - 30px)',
      backgroundColor: 'rgba(' + hexToRgb(grayColor[6]) + ', 0.3)',
    },
  },
  logoLink: {
    ...defaultFont,
    textTransform: 'uppercase',
    padding: '5px 0',
    display: 'block',
    fontSize: '18px',
    textAlign: 'left',
    fontWeight: '400',
    lineHeight: '30px',
    textDecoration: 'none',
    backgroundColor: 'transparent',
    '&,&:hover,&:visited,&:active': {
      color: whiteColor,
    },
  },
  logoLinkLabel: {
    [theme.breakpoints.down('xl')]: {
      display: 'none',
    },
  },
  logoImage: {
    width: '30px',
    display: 'inline-block',
    maxHeight: 30,
    marginLeft: 10,
    marginRight: 15,
    [theme.breakpoints.down('xl')]: {
      marginLeft: -1,
      marginRight: 0,
      textAlign: 'center',
    },
  },
  img: {
    width: '35px',
    top: '28px',
    position: 'absolute',
    verticalAlign: 'middle',
    border: '0',
  },
  background: {
    position: 'absolute',
    zIndex: '1',
    height: '100%',
    width: '100%',
    display: 'block',
    top: '0',
    left: '0',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    '&:after': {
      position: 'absolute',
      zIndex: '3',
      width: '100%',
      height: '100%',
      content: '""',
      display: 'block',
      background: blackColor,
      opacity: '.8',
    },
  },
  list: {
    marginTop: '20px',
    paddingLeft: '0',
    paddingTop: '0',
    paddingBottom: '0',
    marginBottom: '0',
    listStyle: 'none',
    position: 'unset',
  },
  item: {
    position: 'relative',
    display: 'block',
    textDecoration: 'none',
    '&:hover,&:focus,&:visited,&': {
      color: whiteColor,
    },
  },
  itemLink: {
    width: 'auto',
    transition: 'all 300ms linear',
    margin: '10px 15px 0',
    borderRadius: '3px',
    position: 'relative',
    display: 'block',
    padding: '10px 15px',
    backgroundColor: 'transparent',
    minHeight: 50,
    ...defaultFont,
    [theme.breakpoints.down('xl')]: {
      marginLeft: 0,
      marginRight: 0,
      borderRadius: 0,
      textAlign: 'center',
    },
  },
  itemIcon: {
    width: '24px',
    height: '30px',
    fontSize: '24px',
    lineHeight: '30px',
    float: 'left',
    marginRight: '15px',
    textAlign: 'center',
    verticalAlign: 'middle',
    color: 'rgba(' + hexToRgb(whiteColor) + ', 0.8)',
    [theme.breakpoints.down('xl')]: {
      marginRight: 0,
      float: 'none',
    },
  },
  itemText: {
    ...defaultFont,
    margin: '0',
    lineHeight: '30px',
    fontSize: '14px',
    color: whiteColor,
    [theme.breakpoints.down('xl')]: {
      display: 'none',
    },
  },
  whiteFont: {
    color: whiteColor,
  },
  blue: {
    backgroundColor: infoColor[0],
    boxShadow:
      '0 12px 20px -10px rgba(' +
      hexToRgb(infoColor[0]) +
      ',.28), 0 4px 20px 0 rgba(' +
      hexToRgb(blackColor) +
      ',.12), 0 7px 8px -5px rgba(' +
      hexToRgb(infoColor[0]) +
      ',.2)',
    '&:hover,&:focus': {
      backgroundColor: infoColor[0],
      boxShadow:
        '0 12px 20px -10px rgba(' +
        hexToRgb(infoColor[0]) +
        ',.28), 0 4px 20px 0 rgba(' +
        hexToRgb(blackColor) +
        ',.12), 0 7px 8px -5px rgba(' +
        hexToRgb(infoColor[0]) +
        ',.2)',
    },
  },
  sidebar: {
    width: drawerWidth,
    height: '100%',
    [theme.breakpoints.down('xl')]: {
      width: drawerWidthSm,
    },
  },
  sidebarWrapper: {
    position: 'relative',
    height: 'calc(100vh - 75px)',
    overflow: 'auto',
    width: drawerWidth,
    zIndex: '4',
    overflowScrolling: 'touch',
    [theme.breakpoints.down('xl')]: {
      width: drawerWidthSm,
    },
  },
  activePro: {
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      width: '100%',
      bottom: '13px',
    },
  },
});

export default sidebarStyle;
