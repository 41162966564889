import { isBrowser } from '@/helpers/utils';

class PersistentStorage {
  get(key) {
    if (isBrowser()) {
      try {
        return JSON.parse(window.localStorage.getItem(key));
      } catch (err) {
        return null;
      }
    }
  }

  set(key, value) {
    if (isBrowser()) {
      window.localStorage.setItem(key, JSON.stringify(value));
    }
  }

  del(key) {
    if (isBrowser()) {
      window.localStorage.removeItem(key);
    }
  }
}

export default new PersistentStorage();
