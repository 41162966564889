import Button from '@/components/common/Button/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import React from 'react';

const ErrorModal = (props) => {
  const { error, showModal, handleClose } = props;
  return (
    <Dialog
      open={showModal}
      onClose={handleClose}
      aria-labelledby="error-dialog-title"
      aria-describedby="error-dialog-description"
      className="ErrorModal"
    >
      <DialogTitle>Error</DialogTitle>
      <DialogContent>{error.replace(/^error:/i, '')}</DialogContent>
      <DialogActions>
        <Button color="danger" size="sm" onClick={handleClose}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ErrorModal.propTypes = {
  error: PropTypes.string,
  showModal: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default ErrorModal;
