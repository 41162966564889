// export const USER_ROLES = ['Admin', 'QualityControlReviewer', 'Manager', 'Worker'];

import { convertEnum2Options } from '@/helpers/utils';

export const USER_ROLES = [
  {
    prop: 'Admin',
    title: 'Admin',
  },
  {
    prop: 'QualityControlReviewer',
    title: 'Quality Control Reviewer',
  },
  {
    prop: 'Manager',
    title: 'Manager',
  },
  {
    prop: 'Worker',
    title: 'Worker',
  },
];

export const PRODUCT_STATUSES = [
  { prop: 'available', title: 'available' },
  { prop: 'discontinued', title: 'discontinued' },
];

export const ACTIVE_OPTIONS = [
  { prop: undefined, title: 'All' },
  { prop: true, title: 'Yes' },
  { prop: false, title: 'No' },
];

export const WEEK_DAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

export const PARCEL_STATUSES = [
  { prop: 'available', title: 'available' },
  { prop: 'unavailable', title: 'unavailable' },
];

export const PRINT_BATCH_SIDES = [
  { prop: 'top', title: 'top' },
  { prop: 'bottom', title: 'bottom' },
];

export const PRINT_BATCH_TAGS = [
  { prop: 'top', title: 'top' },
  { prop: 'bottom', title: 'bottom' },
];

export const ORDER_STATUSES = {
  new: 'new',
  cancelled: 'cancelled',
  production: 'production',
  hold: 'hold',
  shipped: 'shipped',
  delivered: 'delivered',
};

export const ORDER_STATUSES_OPTIONS = convertEnum2Options(ORDER_STATUSES);

export const CLIENT_COMPANY_TRIGGER_ACTIONS = {
  hold: 'hold',
  cancel: 'cancel',
};

export const CLIENT_COMPANY_TRIGGER_ACTIONS_OPTIONS = convertEnum2Options(CLIENT_COMPANY_TRIGGER_ACTIONS);

export const ORDER_ITEM_STATUSES = {
  new: 'new',
  downloaded: 'downloaded',
  needsRender: 'needsRender',
  rendering: 'rendering',
  rendered: 'rendered',
  needsPrint: 'needsPrint',
  needsBatch: 'needsBatch',
  assignedBatch: 'assignedBatch',
  sentToPrinter: 'sentToPrinter',
  printing: 'printing', // TODO: remove me!
  printed: 'printed',
  needsInventory: 'needsInventory',
  productionReady: 'productionReady',
  pressed: 'pressed',
  qcApproved: 'qcApproved',
  packed: 'packed',
  binnedUnconfirmed: 'binnedUnconfirmed',
  binned: 'binned',
  shipped: 'shipped',
  delivered: 'delivered',
  hold: 'hold',
  cancelled: 'cancelled',
};

export const PRINT_BATCH_STATUSES = {
  new: 'new',
  rendering: 'rendering',
  rendered: 'rendered',
  needsPrint: 'needsPrint',
  printing: 'printing',
  printed: 'printed',
  productionReady: 'productionReady',
  pressed: 'pressed',
  qcApproved: 'qcApproved',
  packing: 'packing',
  packed: 'packed',
  binned: 'binned',
  shipped: 'shipped',
  closed: 'closed',
  cancelled: 'cancelled',
};

export const ROLL_STATUSES = {
  building: 'building',
  printing: 'printing',
  printed: 'printed',
  needsInventory: 'needsInventory',
  productionReady: 'productionReady',
  pressing: 'pressing',
  pressed: 'pressed',
  cancelled: 'cancelled',
};

export const ORDER_ITEM_STATUSES_OPTIONS = [
  { prop: 'new', title: 'new' },
  { prop: 'downloaded', title: 'downloaded' },
  { prop: 'needsRender', title: 'needsRender' },
  { prop: 'rendering', title: 'rendering' },
  { prop: 'rendered', title: 'rendered' },
  { prop: 'needsPrint', title: 'needsPrint' },
  { prop: 'needsBatch', title: 'needsBatch' },
  { prop: 'assignedBatch', title: 'assignedBatch' },
  { prop: 'sentToPrinter', title: 'sentToPrinter' },
  { prop: 'printing', title: 'printing' },
  { prop: 'printed', title: 'printed' },
  { prop: 'needsInventory', title: 'needsInventory' },
  { prop: 'productionReady', title: 'productionReady' },
  { prop: 'pressed', title: 'pressed' },
  { prop: 'qcApproved', title: 'qcApproved' },
  { prop: 'packed', title: 'packed' },
  { prop: 'binnedUnconfirmed', title: 'binnedUnconfirmed' },
  { prop: 'binned', title: 'binned' },
  { prop: 'shipped', title: 'shipped' },
  { prop: 'delivered', title: 'delivered' },
  { prop: 'hold', title: 'hold' },
  { prop: 'cancelled', title: 'cancelled' },
];

export const TEMPLATE_URL_TYPES = [
  { prop: 'all', title: 'all' },
  { prop: 'front', title: 'front' },
  { prop: 'back', title: 'back' },
  { prop: 'right', title: 'right' },
  { prop: 'left', title: 'left' },
  { prop: 'front right', title: 'front right' },
  { prop: 'back right', title: 'back right' },
  { prop: 'front left', title: 'front left' },
  { prop: 'back left', title: 'back left' },
];

export const SHIPPING_CARRIERS2 = [
  { prop: 'USPS', title: 'USPS' },
  { prop: 'UPS', title: 'UPS' },
  { prop: 'FedEx', title: 'FedEx' },
];

export const SHIPPING_PRIORITIES = {
  USPS: [
    { prop: 'First', title: 'First Class' },
    { prop: 'Priority', title: 'Priority Mail' },
    { prop: 'Express', title: 'Express Mail' },
    { prop: 'ParcelSelect', title: 'Parcel Select' },
    { prop: 'LibraryMail', title: 'Library Mail' },
    { prop: 'MediaMail', title: 'Media Mail' },
    { prop: 'CriticalMail', title: 'Critical Mail' },
    { prop: 'FirstClassMailInternational', title: 'First Class Mail International' },
    { prop: 'FirstClassPackageInternationalService', title: 'First Class Package International Service' },
    { prop: 'PriorityMailInternational', title: 'Priority Mail International' },
    { prop: 'ExpressMailInternational', title: 'Express Mail International' },
  ],
  UPS: [
    { prop: 'Ground', title: 'Ground' },
    { prop: 'UPSStandard', title: 'Standard' },
    { prop: 'UPSSaver', title: 'Saver' },
    { prop: 'Express', title: 'Worldwide Express' },
    { prop: 'ExpressPlus', title: 'Worldwide Express Plus' },
    { prop: 'Expedited', title: 'Worldwide Expedited' },
    { prop: 'NextDayAir', title: 'Next Day Air' },
    { prop: 'NextDayAirSaver', title: 'Next Day Air Saver' },
    { prop: 'NextDayAirEarlyAM', title: 'Next Day Air Early AM' },
    { prop: '2ndDayAir', title: 'Second Day Air' },
    { prop: '2ndDayAirAM', title: 'Second Day Air AM' },
    { prop: '3DaySelect', title: 'Three-Day Select' },
  ],
  FedEx: [
    { prop: 'FIRST_OVERNIGHT', title: 'Next Day Air - Delivery by 8:30AM' },
    { prop: 'PRIORITY_OVERNIGHT', title: 'Next Day Air - Delivery by 10:30AM' },
    { prop: 'STANDARD_OVERNIGHT', title: 'Standard Overnight - Delivery by 3PM' },
    { prop: 'FEDEX_2_DAY_AM', title: '2-Day - Delivery by 10:30AM' },
    { prop: 'FEDEX_2_DAY', title: '2-Day - Delivery by 4:30PM' },
    { prop: 'FEDEX_EXPRESS_SAVER', title: '3-Day Express Saver - Delivery by 4:30PM' },
    { prop: 'FEDEX_GROUND', title: 'Ground - 1-5 Business Days' },
    { prop: 'INTERNATIONAL_ECONOMY', title: 'International Economy' },
    { prop: 'INTERNATIONAL_FIRST', title: 'International - Delivery by 8:30AM' },
    { prop: 'INTERNATIONAL_PRIORITY', title: 'International Priority' },
    { prop: 'GROUND_HOME_DELIVERY', title: 'Ground Home Deliver' },
    { prop: 'SMART_POST', title: 'Smart Post' },
  ],
};

export const DEFAULT_FETCH_LIMIT = 25;

export const PAGE_SIZE_OPTIONS = [
  {
    prop: 10,
    title: '10',
  },
  {
    prop: 25,
    title: '25',
  },
  {
    prop: 50,
    title: '50',
  },
  {
    prop: 100,
    title: '100',
  },
];

export const DEFAULT_FROM_ADDRESS = {
  name: 'Print Lab',
  company: '',
  street1: '3912 Shirley Dr',
  city: 'Atlanta',
  state: 'GA',
  zip: '30336',
  country: 'US',
  phone: '6786316001',
};

export const DATE_VALUES = [
  {
    prop: 'createdAt',
    title: 'Created At',
  },
  {
    prop: 'shippedAt',
    title: 'Shipped At',
  },
  {
    prop: 'updatedAt',
    title: 'Updated At',
  },
];

export const FILTER_RULES = [
  {
    prop: 'in',
    title: 'Is',
  },
  {
    prop: 'nin',
    title: 'Is Not',
  },
];

export const DEFAULT_RENDER_SCRIPT = `module.exports = async function render(printBatch, orderItems) {
  return [];
};
`;

export const defaultModificationScript = `
module.exports = async function modifyPayload(payload = {}) {
  return payload;
}
`;

export const INVOICEABLE_OPTIONS = [
  {
    prop: undefined,
    title: 'Any',
  },
  {
    prop: true,
    title: 'True',
  },
  {
    prop: false,
    title: 'False',
  },
];

export const OLD_LENGTH_UNITS = [
  {
    title: 'Inches',
    prop: 'inches',
    rate: 1,
  },
  {
    title: 'Meters',
    prop: 'meters',
    rate: 39.3701,
  },
  {
    title: 'Centimeters',
    prop: 'centimeters',
    rate: 0.393701,
  },
  {
    title: 'Feet',
    prop: 'feet',
    rate: 12,
  },
];

export const LENGTH_UNITS = [
  {
    title: 'Inches',
    prop: 'inches',
    rate: 2.54,
  },
  {
    title: 'Meters',
    prop: 'meters',
    rate: 100,
  },
  {
    title: 'Centimeters',
    prop: 'centimeters',
    rate: 1,
  },
  {
    title: 'Feet',
    prop: 'feet',
    rate: 30.48,
  },
];

export const ONE_DAY_MS = 86400000;

export const TRIGGER_TYPE = {
  webhook: 'webhook',
};

export const TRIGGER_TYPE_OPTIONS = convertEnum2Options(TRIGGER_TYPE);

export const TRIGGER_ARRAY_FIELDS = [
  {
    heading: 'Entities',
    label: 'Entity',
    deleteText: 'Delete entity',
    addText: 'Add entity',
    key: 'entities',
  },
  {
    heading: 'Field names',
    label: 'Field name',
    deleteText: 'Delete field name',
    addText: 'Add field name',
    key: 'fieldNames',
  },
  {
    heading: 'Field values',
    label: 'Field value',
    deleteText: 'Delete field value',
    addText: 'Add field value',
    key: 'fieldValues',
  },
];

export const EASYPOST_ACCOUNTS = [
  {
    title: 'default',
    prop: '',
  },
  {
    title: 'Collage',
    prop: 'collage',
  },
  {
    title: 'Gooten',
    prop: 'gooten',
  },
  {
    title: 'Printify',
    prop: 'printify',
  },
];

export const SHIPPING_PROVIDERS = {
  Easypost: EASYPOST_ACCOUNTS,
  ShipStation: [],
};

export const PERMISSIONS_VALUES = {
  read: 'read',
  write: 'write',
};

export const PERMISSION_SYSTEMS = {
  adminPanel: 'adminPanel',
  orders: 'orders',
  products: 'products',
  parcels: 'parcels',
  clientCompanies: 'clientCompanies',
  qualityControlStations: 'qualityControlStations',
  printBatches: 'printBatches',
  printBatchTypes: 'printBatchTypes',
  printers: 'printers',
  rolls: 'rolls',
  reports: 'reports',
  users: 'users',
  webhooks: 'webhooks',
  apiRequest: 'apiRequest',
  settings: 'settings',
  consolidationScreen: 'consolidationScreen',
  qualityControlScreen: 'qualityControlScreen',
  shippingScreen: 'shippingScreen',
  checkpointSystem: 'checkpointSystem',
};

export const SHIPPING_CARRIERS = {
  AmazonMws: [
    'UPS Rates',
    'USPS Rates',
    'FedEx Rates',
    'UPS Labels',
    'USPS Labels',
    'FedEx Labels',
    'UPS Tracking',
    'USPS Tracking',
    'FedEx Tracking',
  ],
  APC: [
    'parcelConnectBookService',
    'parcelConnectExpeditedDDP',
    'parcelConnectExpeditedDDU',
    'parcelConnectPriorityDDP',
    'parcelConnectPriorityDDPDelcon',
    'parcelConnectPriorityDDU',
    'parcelConnectPriorityDDUDelcon',
    'parcelConnectPriorityDDUPQW',
    'parcelConnectStandardDDU',
    'parcelConnectStandardDDUPQW',
    'parcelConnectPacketDDU',
  ],
  Asendia: ['PMI', 'ePacket', 'IPA', 'ISAL'],
  AsendiaUsa: [
    'ADS',
    'AirFreightInbound',
    'AirFreightOutbound',
    'AsendiaDomesticBoundPrinterMatterExpedited',
    'AsendiaDomesticBoundPrinterMatterGround',
    'AsendiaDomesticFlatsExpedited',
    'AsendiaDomesticFlatsGround',
    'AsendiaDomesticParcelGroundOver1lb',
    'AsendiaDomesticParcelGroundUnder1lb',
    'AsendiaDomesticParcelMAXOver1lb',
    'AsendiaDomesticParcelMAXUnder1lb',
    'AsendiaDomesticParcelOver1lbExpedited',
    'AsendiaDomesticParcelUnder1lbExpedited',
    'AsendiaDomesticPromoParcelExpedited',
    'AsendiaDomesticPromoParcelGround',
    'BulkFreight',
    'BusinessMailCanadaLettermail',
    'BusinessMailCanadaLettermailMachineable',
    'BusinessMailEconomy',
    'BusinessMailEconomyLPWholesale',
    'BusinessMailEconomySPWholesale',
    'BusinessMailIPA',
    'BusinessMailISAL',
    'BusinessMailPriority',
    'BusinessMailPriorityLPWholesale',
    'BusinessMailPrioritySPWholesale',
    'MarketingMailCanadaPersonalizedLCP',
    'MarketingMailCanadaPersonalizedMachineable',
    'MarketingMailCanadaPersonalizedNDG',
    'MarketingMailEconomy',
    'MarketingMailIPA',
    'MarketingMailISAL',
    'MarketingMailPriority',
    'PublicationsCanadaLCP',
    'PublicationsCanadaNDG',
    'PublicationsEconomy',
    'PublicationsIPA',
    'PublicationsISAL',
    'PublicationsPriority',
    'ePAQElite',
    'ePAQEliteCustom',
    'ePAQEliteDAP',
    'ePAQEliteDDP',
    'ePAQEliteDDPOversized',
    'ePAQEliteDPD',
    'ePAQEliteDirectAccessCanadaDDP',
    'ePAQEliteOversized',
    'ePAQPlus',
    'ePAQPlusCustom',
    'ePAQPlusCustomsPrepaid',
    'ePAQPlusDAP',
    'ePAQPlusDDP',
    'ePAQPlusEconomy',
    'ePAQPlusWholesale',
    'ePAQPlusePacket',
    'ePAQPlusePacketCanadaCustomsPrePaid',
    'ePAQPlusePacketCanadaDDP',
    'ePAQReturnsDomestic',
    'ePAQReturnsInternational',
    'ePAQReturnsInternational',
    'ePAQSelect',
    'ePAQSelectCustom',
    'ePAQSelectCustomsPrepaidByShopper',
    'ePAQSelectDAP',
    'ePAQSelectDDP',
    'ePAQSelectDDPDirectAccess',
    'ePAQSelectDirectAccess',
    'ePAQSelectDirectAccessCanadaDDP',
    'ePAQSelectEconomy',
    'ePAQSelectOversized',
    'ePAQSelectOversizedDDP',
    'ePAQSelectPMEI',
    'ePAQSelectPMEICanadaCustomsPrePaid',
    'ePAQSelectPMEIPCPostage',
    'ePAQSelectPMI',
    'ePAQSelectPMICanadaCustomsPrepaid',
    'ePAQSelectPMICanadaDDP',
    'ePAQSelectPMINonPresort',
    'ePAQSelectPMIPCPostage',
    'ePAQStandard',
    'ePAQStandardCustom',
    'ePAQStandardEconomy',
    'ePAQStandardIPA',
    'ePAQStandardISAL',
    'ePaqSelectPMEINonPresort',
  ],
  AustraliaPost: [
    'ExpressPost',
    'ExpressPostSignature',
    'ParcelPost',
    'ParcelPostSignature',
    'ParcelPostExtra',
    'ParcelPostWinePlusSignature',
  ],
  AxlehireV3: ['AxleHireDelivery'],
  BetterTrucks: [],
  Bond: ['Standard'],
  BorderGuru: ['ECONOMY', 'PRIORITY', 'EXPEDITED'],
  Cainiao: [],
  CanadaPost: [
    'RegularParcel',
    'ExpeditedParcel',
    'Xpresspost',
    'XpresspostCertified',
    'Priority',
    'LibraryBooks',
    'ExpeditedParcelUSA',
    'PriorityWorldwideEnvelopeUSA',
    'PriorityWorldwidePakUSA',
    'PriorityWorldwideParcelUSA',
    'SmallPacketUSAAir',
    'TrackedPacketUSA',
    'TrackedPacketUSALVM',
    'XpresspostUSA',
    'XpresspostInternational',
    'InternationalParcelAir',
    'InternationalParcelSurface',
    'PriorityWorldwideEnvelopeIntl',
    'PriorityWorldwidePakIntl',
    'PriorityWorldwideParcelIntl',
    'SmallPacketInternationalAir',
    'SmallPacketInternationalSurface',
    'TrackedPacketInternational',
  ],
  Canpar: [
    'Ground',
    'SelectLetter',
    'SelectPak',
    'Select',
    'OvernightLetter',
    'OvernightPak',
    'Overnight',
    'SelectUSA',
    'USAPak',
    'USALetter',
    'USA',
    'International',
  ],
  ColumbusLastMile: ['DISTRIBUTION', 'Same Day'],
  Chronopost: [],
  ColisPrive: ['Parcel'],
  Colissimo: [],
  CourierExpress: ['BASIC_PARCEL'],
  CouriersPlease: [
    'DomesticPrioritySignature',
    'DomesticPriority',
    'DomesticOffPeakSignature',
    'DomesticOffPeak',
    'GoldDomesticSignature',
    'GoldDomestic',
    'AustralianCityExpressSignature',
    'AustralianCityExpress',
    'DomesticSaverSignature',
    'DomesticSaver',
    'RoadExpress',
    '5KgSatchel',
    '3KgSatchel',
    '1KgSatchel',
    '5KgSatchelATL',
    '3KgSatchelATL',
    '1KgSatchelATL',
    '500GramSatchel',
    '500GramSatchelATL',
    '25KgParcel',
    '10KgParcel',
    '5KgParcel',
    '3KgParcel',
    '1KgParcel',
    '500GramParcel',
    '500GramParcelATL',
    'ExpressInternationalPriority',
    'InternationalSaver',
    'InternationalExpressImport',
    'ExpressInternationalPriority',
    'InternationalExpress',
  ],
  DaiPost: ['DomesticTracked', 'InternationalEconomy', 'InternationalStandard', 'InternationalExpress'],
  DeutschePost: ['PacketPlus'],
  DeutschePostUK: [
    'PriorityPacketPlus',
    'PriorityPacket',
    'PriorityPacketTracked',
    'BusinessMailRegistered',
    'StandardPacket',
    'BusinessMailStandard',
  ],
  DHLEcommerceAsia: ['Packet', 'PacketPlus', 'ParcelDirect', 'ParcelDirectExpedited'],
  DhlEcs: [
    'DHLParcelExpedited',
    'DHLParcelExpeditedMax',
    'DHLParcelGround',
    'DHLBPMExpedited',
    'DHLBPMGround',
    'DHLParcelInternationalDirect',
    'DHLParcelInternationalStandard',
    'DHLPacketInternational',
    'DHLParcelInternationalDirectPriority',
    'DHLParcelInternationalDirectStandard',
  ],
  DHLExpress: [
    'BreakBulkEconomy',
    'BreakBulkExpress',
    'DomesticEconomySelect',
    'DomesticExpress',
    'DomesticExpress1030',
    'DomesticExpress1200',
    'EconomySelect',
    'EconomySelectNonDoc',
    'EuroPack',
    'EuropackNonDoc',
    'Express1030',
    'Express1030NonDoc',
    'Express1200NonDoc',
    'Express1200',
    'Express900',
    'Express900NonDoc',
    'ExpressEasy',
    'ExpressEasyNonDoc',
    'ExpressEnvelope',
    'ExpressWorldwide',
    'ExpressWorldwideB2C',
    'ExpressWorldwideB2CNonDoc',
    'ExpressWorldwideECX',
    'ExpressWorldwideNonDoc',
    'FreightWorldwide',
    'GlobalmailBusiness',
    'JetLine',
    'JumboBox',
    'LogisticsServices',
    'SameDay',
    'SecureLine',
    'SprintLine',
  ],
  DHLFreight: [],
  DHLGermany: [],
  DPD: [
    'DPDCLASSIC',
    'DPD8:30',
    'DPD10:00',
    'DPD12:00',
    'DPD18:00',
    'DPDEXPRESS',
    'DPDPARCELLETTER',
    'DPDPARCELLETTERPLUS',
    'DPDINTERNATIONALMAIL',
  ],
  DPDUK: [
    'AirExpressInternationalAir',
    'AirClassicInternationalAir',
    'ParcelSunday',
    'FreightParcelSunday',
    'PalletSunday',
    'PalletDpdClassic',
    'ExpresspakDpdClassic',
    'ExpresspakSunday',
    'ParcelDpdClassic',
    'ParcelDpdTwoDay',
    'ParcelDpdNextDay',
    'ParcelDpd12',
    'ParcelDpd10',
    'ParcelDpd10',
    'ParcelReturnToShop',
    'ParcelSaturday',
    'ParcelSaturday12',
    'ParcelSaturday10',
    'ParcelSaturday10',
    'ParcelSunday12',
    'FreightParcelDpdClassic',
    'FreightParcelSunday12',
    'ExpresspakDpdNextDay',
    'ExpresspakDpd12',
    'ExpresspakDpd10',
    'ExpresspakDpd10',
    'ExpresspakSaturday',
    'ExpresspakSaturday12',
    'ExpresspakSaturday10',
    'ExpresspakSaturday10',
    'ExpresspakSunday12',
    'PalletSunday12',
    'PalletDpdTwoDay',
    'PalletDpdNextDay',
    'PalletDpd12',
    'PalletDpd10',
    'PalletSaturday',
    'PalletSaturday12',
    'PalletSaturday10',
    'FreightParcelDpdTwoDay',
    'FreightParcelDpdNextDay',
    'FreightParcelDpd12',
    'FreightParcelDpd10',
    'FreightParcelSaturday',
    'FreightParcelSaturday12',
    'FreightParcelSaturday10',
  ],
  ChinaEMS: [],
  ePostGlobal: [
    'CourierServiceDDP',
    'CourierServiceDDU',
    'DomesticEconomyParcel',
    'DomesticParcelBPM',
    'DomesticPriorityParcel',
    'DomesticPriorityParcelBPM',
    'EMIService',
    'EconomyParcelService',
    'IPAService',
    'ISALService',
    'PMIService',
    'PriorityParcelDDP',
    'PriorityParcelDDU',
    'PriorityParcelDeliveryConfirmationDDP',
    'PriorityParcelDeliveryConfirmationDDU',
    'ePacketService',
  ],
  Estafeta: ['NextDayBy930', 'NextDayBy1130', 'NextDay', 'Ground', 'TwoDay', 'LTL'],
  Fastway: ['Parcel', 'Satchel'],
  FedEx: [
    'FEDEX_GROUND',
    'FEDEX_2_DAY',
    'FEDEX_2_DAY_AM',
    'FEDEX_EXPRESS_SAVER',
    'STANDARD_OVERNIGHT',
    'FIRST_OVERNIGHT',
    'PRIORITY_OVERNIGHT',
    'INTERNATIONAL_ECONOMY',
    'INTERNATIONAL_FIRST',
    'INTERNATIONAL_PRIORITY',
    'GROUND_HOME_DELIVERY',
    'SMART_POST',
  ],
  FedExCrossBorder: ['CBEC', 'CBECL', 'CBECP'],
  FedExMailview: [],
  FedExSameDayCity: ['EconomyService', 'StandardService', 'PriorityService', 'LastMile'],
  FedexSmartPost: ['SMART_POST'],
  FirstMile: [],
  Globegistics: [
    'PMEI',
    'PMI',
    'eComDomestic',
    'eComEurope',
    'eComExpress',
    'eComExtra',
    'eComIPA',
    'eComISAL',
    'eComPMEIDutyPaid',
    'eComPMIDutyPaid',
    'eComPacket',
    'eComPacketDDP',
    'eComPriority',
    'eComStandard',
    'eComTrackedDDP',
    'eComTrackedDDU',
  ],
  GSO: [
    'EarlyPriorityOvernight',
    'PriorityOvernight',
    'CaliforniaParcelService',
    'SaturdayDeliveryService',
    'EarlySaturdayService',
    'Ground',
    'Overnight',
  ],
  Hermes: ['DomesticDelivery', 'DomesticDeliverySigned', 'InternationalDelivery', 'InternationalDeliverySigned'],
  HongKongPost: [],
  InterlinkExpress: [
    'InterlinkAirClassicInternationalAir',
    'InterlinkAirExpressInternationalAir',
    'InterlinkExpresspak1By10:30',
    'InterlinkExpresspak1By12',
    'InterlinkExpresspak1NextDay',
    'InterlinkExpresspak1Saturday',
    'InterlinkExpresspak1SaturdayBy10:30',
    'InterlinkExpresspak1SaturdayBy12',
    'InterlinkExpresspak1Sunday',
    'InterlinkExpresspak1SundayBy12',
    'InterlinkExpresspak5By10',
    'InterlinkExpresspak5By10:30',
    'InterlinkExpresspak5By12',
    'InterlinkExpresspak5NextDay',
    'InterlinkExpresspak5Saturday',
    'InterlinkExpresspak5SaturdayBy10',
    'InterlinkExpresspak5SaturdayBy10:30',
    'InterlinkExpresspak5SaturdayBy12',
    'InterlinkExpresspak5Sunday',
    'InterlinkExpresspak5SundayBy12',
    'InterlinkFreightBy10',
    'InterlinkFreightBy12',
    'InterlinkFreightNextDay',
    'InterlinkFreightSaturday',
    'InterlinkFreightSaturdayBy10',
    'InterlinkFreightSaturdayBy12',
    'InterlinkFreightSunday',
    'InterlinkFreightSundayBy12',
    'InterlinkParcelBy10',
    'InterlinkParcelBy10:30',
    'InterlinkParcelBy12',
    'InterlinkParcelDpdEuropeByRoad',
    'InterlinkParcelNextDay',
    'InterlinkParcelReturn',
    'InterlinkParcelReturnToShop',
    'InterlinkParcelSaturday',
    'InterlinkParcelSaturdayBy10',
    'InterlinkParcelSaturdayBy10:30',
    'InterlinkParcelSaturdayBy12',
    'InterlinkParcelShipToShop',
    'InterlinkParcelSunday',
    'InterlinkParcelSundayBy12',
    'InterlinkParcelTwoDay',
    'InterlinkPickupParcelDpdEuropeByRoad',
  ],
  JancoFreight: [],
  JPPost: [],
  KuronekoYamato: [],
  LaPoste: [],
  LaserShipV2: ['SameDay', 'NextDay', 'Weekend'],
  Liefery: ['TourAppointment20161124From1600To1900'],
  LoomisExpress: ['LoomisGround', 'LoomisExpress1800', 'LoomisExpress1200', 'LoomisExpress900'],
  LSO: [
    'GroundEarly',
    'GroundBasic',
    'PriorityBasic',
    'PriorityEarly',
    'PrioritySaturday',
    'Priority2ndDay',
    'SameDay',
  ],
  Newgistics: [
    'ParcelSelect',
    'ParcelSelectLightweight',
    'Ground',
    'Express',
    'FirstClassMail',
    'PriorityMail',
    'BoundPrintedMatter',
  ],
  OnTrac: ['Sunrise', 'Gold', 'OnTracGround', 'SameDay', 'PalletizedFreight'],
  OsmWorldwide: [
    'First',
    'Expedited',
    'ParcelSelectLightweight',
    'Priority',
    'BPM',
    'ParcelSelect',
    'MediaMail',
    'MarketingParcel',
    'MarketingParcelTracked',
  ],
  Parcelforce: [],
  PassportGlobal: [],
  PcfFinalMile: [],
  Pilot: [],
  PostNL: [],
  PostNord: [],
  Purolator: [
    'PurolatorExpress',
    'PurolatorExpress12PM',
    'PurolatorExpressPack12PM',
    'PurolatorExpressBox12PM',
    'PurolatorExpressEnvelope12PM',
    'PurolatorExpress1030AM',
    'PurolatorExpress9AM',
    'PurolatorExpressBox',
    'PurolatorExpressBox1030AM',
    'PurolatorExpressBox9AM',
    'PurolatorExpressBoxEvening',
    'PurolatorExpressBoxInternational',
    'PurolatorExpressBoxInternational1030AM',
    'PurolatorExpressBoxInternational1200',
    'PurolatorExpressBoxInternational9AM',
    'PurolatorExpressBoxUS',
    'PurolatorExpressBoxUS1030AM',
    'PurolatorExpressBoxUS1200',
    'PurolatorExpressBoxUS9AM',
    'PurolatorExpressEnvelope',
    'PurolatorExpressEnvelope1030AM',
    'PurolatorExpressEnvelope9AM',
    'PurolatorExpressEnvelopeEvening',
    'PurolatorExpressEnvelopeInternational',
    'PurolatorExpressEnvelopeInternational1030AM',
    'PurolatorExpressEnvelopeInternational1200',
    'PurolatorExpressEnvelopeInternational9AM',
    'PurolatorExpressEnvelopeUS',
    'PurolatorExpressEnvelopeUS1030AM',
    'PurolatorExpressEnvelopeUS1200',
    'PurolatorExpressEnvelopeUS9AM',
    'PurolatorExpressEvening',
    'PurolatorExpressInternational',
    'PurolatorExpressInternational1030AM',
    'PurolatorExpressInternational1200',
    'PurolatorExpressInternational9AM',
    'PurolatorExpressPack',
    'PurolatorExpressPack1030AM',
    'PurolatorExpressPack9AM',
    'PurolatorExpressPackEvening',
    'PurolatorExpressPackInternational',
    'PurolatorExpressPackInternational1030AM',
    'PurolatorExpressPackInternational1200',
    'PurolatorExpressPackInternational9AM',
    'PurolatorExpressPackUS',
    'PurolatorExpressPackUS1030AM',
    'PurolatorExpressPackUS1200',
    'PurolatorExpressPackUS9AM',
    'PurolatorExpressUS',
    'PurolatorExpressUS1030AM',
    'PurolatorExpressUS1200',
    'PurolatorExpressUS9AM',
    'PurolatorGround',
    'PurolatorGround1030AM',
    'PurolatorGround9AM',
    'PurolatorGroundDistribution',
    'PurolatorGroundEvening',
    'PurolatorGroundRegional',
    'PurolatorGroundUS',
  ],
  RoyalMail: [
    'InternationalSigned',
    'InternationalStandard',
    'InternationalTracked',
    'InternationalTrackedAndSigned',
    '1stClass',
    '1stClassSignedFor',
    '2ndClass',
    '2ndClassSignedFor',
    'RoyalMail24',
    'RoyalMail24SignedFor',
    'RoyalMail48',
    'RoyalMail48SignedFor',
    'SpecialDeliveryGuaranteed1pm',
    'SpecialDeliveryGuaranteed9am',
    'StandardLetter1stClass',
    'StandardLetter1stClassSignedFor',
    'StandardLetter2ndClass',
    'StandardLetter2ndClassSignedFor',
    'Tracked24',
    'Tracked24HighVolume',
    'Tracked24HighVolumeSignature',
    'Tracked24Signature',
    'Tracked48',
    'Tracked48HighVolume',
    'Tracked48HighVolumeSignature',
    'Tracked48Signature',
  ],
  Seko: [],
  OmniParcel: ['eCommerce Standard Tracked', 'eCommerce Express Tracked', 'Domestic Express', 'Domestic Standard'],
  SFExpress: [
    'International Standard Express - Doc',
    'International Standard Express - Parcel',
    'International Economy Express - Pilot',
    'International Economy Express - Doc',
  ],
  SingaporePost: [],
  SpeeDee: ['SpeeDeeDelivery'],
  StarTrack: ['StartrackExpress', 'StartrackPremium', 'StartrackFixedPricePremium'],
  Toll: [],
  TForce: ['SameDay', 'SameDayWhiteGlove', 'NextDay', 'NextDayWhiteGlove'],
  UDS: ['DeliveryService'],
  Ukrposhta: [],
  UPS: [
    'Ground',
    'UPSStandard',
    'UPSSaver',
    'Express',
    'ExpressPlus',
    'Expedited',
    'NextDayAir',
    'NextDayAirSaver',
    'NextDayAirEarlyAM',
    '2ndDayAir',
    '2ndDayAirAM',
    '3DaySelect',
  ],
  UPSSurePost: ['SurePostUnder1Lb', 'SurePostOver1Lb'],
  UPSIparcel: [],
  UPSMailInnovations: [
    'First',
    'Priority',
    'ExpeditedMailInnovations',
    'PriorityMailInnovations',
    'EconomyMailInnovations',
  ],
  USPS: [
    'First',
    'Priority',
    'Express',
    'ParcelSelect',
    'LibraryMail',
    'MediaMail',
    'FirstClassMailInternational',
    'FirstClassPackageInternationalService',
    'PriorityMailInternational',
    'ExpressMailInternational',
  ],
  Veho: ['nextDay', 'sameDay'],
  Yanwen: [],
};

export const PRINT_BATCH_TYPE_EXCLUSIONS = [
  61,
  15,
  49,
  4,
  47,
  2,
  48,
  3,
  57,
  11,
  58,
  12,
  59,
  13,
  60,
  14,
  56,
  10,
  54,
  9,
  53,
  8,
  65,
  19,
  66,
  20,
  63,
  17,
  64,
  18,
  69,
  23,
  70,
  24,
  71,
  25,
  72,
  26,
  74,
  28,
  75,
  29,
  76,
  30,
  50,
  5,
  51,
  6,
  52,
  7,
  62,
  16,
  77,
  31,
  67,
  21,
  68,
  22,
  73,
  27,
];
