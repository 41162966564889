import { adaptV4Theme, createTheme } from '@mui/material/styles';
// import { red } from '@mui/material/colors';

// Create a theme instance.
const theme = createTheme(
  adaptV4Theme({
    // palette: {
    //   primary: {
    //     main: '#556cd6',
    //   },
    //   secondary: {
    //     main: '#19857b',
    //   },
    //   error: {
    //     main: red.A400,
    //   },
    // },
    // typography: {
    // },
  })
);

export default theme;
