import { useAlert } from '@/components/common/Alert/AlertProvider';
import Button from '@/components/common/Button/Button';
import { FormEditTextField } from '@/components/common/FormEditTextField/FormEditTextField';
import { LOGIN } from '@/gql/users';
import store from '@/helpers/persistent';
import { useLazyQuery } from '@apollo/react-hooks';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';

import styles from './Login.module.scss';

export const Login = () => {
  const alert = useAlert();
  const [username, setUsername] = useState('');
  // const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [login, { error, data }] = useLazyQuery(LOGIN);

  useEffect(() => error && alert.error('Email address or password mismatch'), [error]);

  useEffect(() => {
    if (data) {
      const {
        login: { token, user },
      } = data;

      if (token) {
        store.set('auth_token', token);
        store.set('user', user);
        alert.success(`You're logged in`);
        window.location.reload();
        return;
      }

      alert.error(`Unable to login with provided credentials`);
    }
  }, [data]);

  const handleLogin = () => {
    login({
      variables: {
        username,
        // email,
        password,
      },
    });
  };

  const onFormKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };

  return (
    <Container className={styles.Login} maxWidth="xs">
      <Paper className={styles['Login--inner']}>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <div>
          <FormEditTextField
            label="Username"
            value={username}
            onChange={(event) => setUsername(event.target.value)}
            onKeyPress={onFormKeyPress}
          />
          {/* <FormEditTextField
            type="email"
            label="Email address"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            onKeyPress={onFormKeyPress}
          /> */}
          <FormEditTextField
            type="password"
            label="Password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            onKeyPress={onFormKeyPress}
          />

          <Button fullWidth variant="contained" color="primary" onClick={() => handleLogin()}>
            Login
          </Button>
        </div>
      </Paper>
    </Container>
  );
};
