import ErrorModal from '@/components/common/Modal/ErrorModal';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import React, { useContext, useState } from 'react';

const SNACK_TIMEOUT = 6000;
const SNACK_ERROR_TIMEOUT = 12000;

export const Alert = (props) => <MuiAlert elevation={6} variant="filled" {...props} />;

export const ALERT_TYPES = {
  info: 'info',
  success: 'success',
  warn: 'warning',
  error: 'error',
};

const AlertContext = React.createContext({});

export const AlertProvider = (props) => {
  const [message, setMessage] = useState('');
  const [isShown, setIsShown] = useState(false);
  const [alertType, setAlertType] = useState(ALERT_TYPES.info);

  const onClose = () => {
    setIsShown(false);
  };

  const info = (msg) => {
    setIsShown(true);
    setMessage(msg);
    setAlertType(ALERT_TYPES.info);
  };

  const success = (msg) => {
    setIsShown(true);
    setMessage(msg);
    setAlertType(ALERT_TYPES.success);
  };

  const warn = (msg) => {
    setIsShown(true);
    setMessage(msg);
    setAlertType(ALERT_TYPES.warn);
  };

  const error = (err) => {
    let msg;

    if (typeof err === 'string') {
      msg = err;
    } else if (err instanceof Error) {
      try {
        msg = err.networkError.result.errors.map((e) => e.message).join('\n');
      } catch {
        msg = err.toString();
      }
    } else if (err && (err.message || err.description)) {
      msg = err.description || err.message;
    } else {
      msg = `${err.status || ''} ${err.statusText || ''}`;
    }

    msg = msg.trim();

    setIsShown(true);
    setMessage(msg);
    setAlertType(ALERT_TYPES.error);
  };

  const isError = alertType === ALERT_TYPES.error;

  return (
    <AlertContext.Provider value={{ message, isShown, alertType, info, success, warn, error, close: onClose }}>
      <Snackbar
        autoHideDuration={isError ? SNACK_ERROR_TIMEOUT : SNACK_TIMEOUT}
        open={isShown && !isError}
        onClose={onClose}
      >
        <div>
          <Alert severity={alertType} onClose={onClose}>
            {message}
          </Alert>
        </div>
      </Snackbar>
      <ErrorModal error={message} showModal={isShown && isError} handleClose={onClose} />

      {props.children}
    </AlertContext.Provider>
  );
};

export const useAlert = () => useContext(AlertContext);
