import '@/styles/_app.scss';
import 'prismjs';

import { AlertProvider } from '@/components/common/Alert/AlertProvider';
import GlobalProgress from '@/components/common/GlobalProgress/GlobalProgress';
import Navbar from '@/components/common/Navbars/Navbar';
import Sidebar from '@/components/common/Sidebar/Sidebar';
import { Login } from '@/components/views/Account/Login';
import { withApollo } from '@/helpers/apollo';
import { useCheckUser } from '@/hooks/useCheckUser';
import createEmotionCache from '@/src/createEmotionCache';
import createTssCache from '@/src/createTssCache';
import theme from '@/src/theme';
import { CacheProvider } from '@emotion/react';
import { StyledEngineProvider, ThemeProvider, createTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
// import getConfig from 'next/config';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { TssCacheProvider } from 'tss-react';

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();
const clientSideTssCache = createTssCache();

const App = (props) => {
  const { Component, emotionCache = clientSideEmotionCache, tssCache = clientSideTssCache, pageProps } = props;
  const [loadCurrentUser, currentUser, currentUserLoading] = useCheckUser();
  const [isBrowser, setIsBrowser] = useState(false);

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }

    loadCurrentUser();
    setIsBrowser(true);
  }, []);

  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const router = useRouter();
  useEffect(() => {
    router.events.on('routeChangeStart', () => {
      setIsLoaderVisible(true);
    });
    router.events.on('routeChangeComplete', () => {
      setIsLoaderVisible(false);
    });
    router.events.on('routeChangeError', () => {
      setIsLoaderVisible(false);
    });
  }, []);

  return (
    <CacheProvider value={emotionCache}>
      <TssCacheProvider value={tssCache}>
        <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1.0, shrink-to-fit=no" />
          <link rel="icon" href="/favicon.ico" />
          <title>PeachDigital Admin UI</title>
        </Head>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <GlobalProgress show={isLoaderVisible} />
            <AlertProvider>
              {!currentUser && !currentUserLoading && <Login />}

              {currentUser && isBrowser && (
                <Grid container spacing={0}>
                  <Grid item className="App-Menu-wrapper">
                    <Sidebar />
                  </Grid>
                  <Grid item xs className="App-Component-wrapper">
                    <Navbar />
                    <Component {...pageProps} />
                  </Grid>
                </Grid>
              )}
            </AlertProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </TssCacheProvider>
    </CacheProvider>
  );
};

export default withApollo()(App);
