import CustomFormControl from '@/components/common/CustomFormControl/CustomFormControl';
import Check from '@mui/icons-material/Check';
// @mui/icons-material
import Clear from '@mui/icons-material/Clear';
import Input from '@mui/material/Input';
// @mui/material components
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

// core components
import styles from './CustomInput.style.js';

const useStyles = makeStyles(styles);

export default function CustomInput(props) {
  const classes = useStyles();
  const { formControlProps, labelText, id, labelProps, inputProps, error, success } = props;
  const labelClasses = clsx({
    [' ' + classes.labelRootError]: error,
    [' ' + classes.labelRootSuccess]: success && !error,
  });
  const underlineClasses = clsx({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true,
  });
  const marginTop = clsx({
    [classes.marginTop]: labelText === undefined,
  });
  return (
    <CustomFormControl
      formControlProps={formControlProps}
      labelText={labelText}
      labelClasses={labelClasses}
      labelProps={labelProps}
      id={id}
      inputType={inputProps.type}
    >
      <Input
        classes={{
          root: marginTop,
          disabled: classes.disabled,
          underline: underlineClasses,
        }}
        id={id}
        {...inputProps}
      />
      {error ? (
        <Clear className={classes.feedback + ' ' + classes.labelRootError} />
      ) : success ? (
        <Check className={classes.feedback + ' ' + classes.labelRootSuccess} />
      ) : null}
    </CustomFormControl>
  );
}

CustomInput.propTypes = {
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  error: PropTypes.bool,
  success: PropTypes.bool,
};
